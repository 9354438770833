<template>
  <div>
    <el-drawer
      :title="title"
      :visible.sync="setShow"
      direction="rtl"
      @close="closeEvent"
    >
      <div class="el-drawer--content">
        <div class="el-drawer--content__body">
          <div class="spaced">
            <span>Customer</span>
            <b>{{ formatToTitleCase(customer.name) }}</b>
          </div>
          <div class="spaced">
            <span>Contact Person Email Address</span>
            <b>{{ customer.email }}</b>
          </div>
          <div class="spaced">
            <span>Customer status</span>
            <el-tag :type="setType(customer.status)">
              {{ formatText(customer.status) }}
            </el-tag>
          </div>
          <div v-if="account === 'individual'" class="spaced">
            <span>Gardener </span>
            <b>{{ customer.gardener }}</b>
          </div>
          <div class="spaced">
            <span>Expected Amount </span>
            <b>{{ formatPrice(customer.subscription_amount) }}</b>
          </div>
          <div class="spaced">
            <span>Billing Date</span>
            <b>{{ formatDate(customer.billing_date, "m do, yyy") }}</b>
          </div>
          <div v-if="account === 'corporate'" class="spaced">
            <span>Employees</span>
            <b
              >{{
                customer.employees_id != null ? customer.employees_id.length : 0
              }}
              employees
              <span
                v-if="customer.employees_id != null"
                @click="viewEmployees"
                class="view-employees"
                >View</span
              >
            </b>
          </div>
          <div class="spaced">
            <span>Payment status</span>
            <el-tag :type="setType(customer.payment_status)">
              {{ formatText(customer.payment_status) }}
            </el-tag>
          </div>
          <div class="spaced">
            <span>Reason</span>
            <b>{{ formatText(customer.reason) }}</b>
          </div>
          <div class="spaced">
            <span> Amount Paid</span>
            <b>{{ formatPrice(customer.amount_paid) }}</b>
          </div>
          <div class="spaced">
            <span>Payment Date</span>
            <b>{{ formatDate(customer.payment_date, "m do, yyy") }}</b>
          </div>
          <div class="spaced">
            <span>Conversion</span>
            <span v-if="customer.conversion.length">
              <el-tag
                v-for="(type, index) in customer.conversion"
                :key="index"
                :type="setType(type)"
              >
                {{ formatText(type) }}
              </el-tag>
            </span>
            <p v-else>-</p>
          </div>
          <div class="sub-head">Additional Feedback</div>
          <div>
            <span class="feedback">{{ customer.feedback || "-" }}</span>
          </div>
          <div class="top-space">
            <eden-overview-card :title="'Plan details'">
              <template slot="content">
                <div>
                  <template v-if="customer.plan">
                    <el-row type="flex" class="flex-wrap">
                      <el-col :span="24">
                        <subscription-service
                          v-for="(value, key, index) in customer.plan"
                          :key="index"
                          :service="key"
                          :service-config="value"
                          :hide-count="true"
                        />
                      </el-col>
                    </el-row>
                  </template>
                  <p v-else class="empty-indicator font-sm text-grey-tertiary">
                    This customer has no subscription plan.
                  </p>
                </div>
              </template>
            </eden-overview-card>
          </div>
        </div>
      </div>
    </el-drawer>
    <employees-list
      :show.sync="showEmployeeStatus"
      :corporate-account="{
        name: this.customer.company_name,
        employees_id: this.customer.employees_id,
      }"
    />
  </div>
</template>

<script>
import EmployeesList from "../Corporate/EmployeesList";
import SubscriptionService from "@/components/Subscriptions/Subscription/SubscriptionService";

export default {
  name: "PaymentInformation",
  components: {
    SubscriptionService,
    EmployeesList,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
    account: {
      type: String,
      default: "individual",
    },
  },
  data() {
    return {
      showEmployeeStatus: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return (
        this.formatToTitleCase(this.customer.name) ||
        this.formatText(this.customer.company_name)
      );
    },
  },
  methods: {
    viewEmployees() {
      this.showEmployeeStatus = true;
    },
    closeEvent() {
      this.setShow = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 20px 0 10px;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #21312a;
  }
}

.eden-plan--summary {
  width: 100%;
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 20px;

  span {
    color: #798b83;
    width: 50%;
  }

  p {
    color: #21312a;
    font-size: 1rem;
  }
}

.eden-customer-service {
  margin-bottom: 40px;
}
.spaced {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.sub-head {
  border-bottom: 1px solid #f0f4f2;
  padding-bottom: 2px;
  font-size: 12px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 10px;
}
.feedback {
  margin-bottom: 100px;
  word-break: keep-all !important;
  word-wrap: break-word !important;
}
.top-space {
  margin-top: 30px;
  word-break: keep-all !important;
  word-wrap: break-word !important;
}
.view-employees {
  color: var(--eden-green-primary);
  cursor: pointer;
}
</style>
