<template>
  <div>
    <el-drawer
      :with-header="false"
      :visible.sync="setShow"
      direction="rtl"
      @close="closeEvent"
    >
      <div class="el-drawer--content">
        <div class="head-title" @click="closeEvent">
          <span class="faint-circle"><i class="eden-icon-arrow-left"></i></span>
          Back to {{ corporateAccount.name }}
        </div>
        <eden-loader v-if="loading" />
        <div v-else class="el-drawer--content__body">
          <eden-table-actions
            :title="`${employeeData.length} Employees`"
            @search="search"
          />
          <el-table :data="employees">
            <el-table-column>
              <template slot="header">
                <div class="table--header">
                  <span>Name</span>
                </div>
              </template>
              <template slot-scope="scope">
                <router-link
                  :to="{
                    name: 'customers.individual',
                    params: { id: scope.row.id },
                  }"
                >
                  <span class="font-sm text-primary">
                    {{ formatName(scope.row.name) }}</span
                  ></router-link
                >
                <span class="font-xsm capitalize">{{
                  formatName(scope.row.gardener)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <div class="table--header">
                  <span>Email</span>
                </div>
              </template>
              <template slot-scope="scope">
                <span> {{ formatName(scope.row.email) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import paymentTracker from "@/requests/gardeners/payment-tracker";

export default {
  name: "EmployeesList",
  components: {},
  props: {
    corporateAccount: {
      type: Object,
      default() {
        return {};
      },
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      employeeData: {},
      searchText: "",
      loading: true,
    };
  },
  watch: {
    show() {
      if (this.show) {
        this.getEmployees();
      }
    },
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return "Back to ";
    },
    employees() {
      if (this.searchText !== "") {
        const employee_data = this.employeeData.filter((employee) => {
          const search_text = this.searchText.toLowerCase();
          return (
            employee.name.toLowerCase().includes(search_text) ||
            employee.email.toLowerCase().includes(search_text)
          );
        });
        return employee_data;
      }
      return this.employeeData;
    },
  },
  methods: {
    search(text) {
      this.searchText = text;
    },
    getEmployees() {
      this.loading = true;
      paymentTracker
        .getEmployees(this.corporateAccount.employees_id)
        .then((response) => {
          this.loading = false;
          this.employeeData = response.data.data;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    closeEvent() {
      this.setShow = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 20px 0 10px;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #21312a;
  }
}

.eden-plan--summary {
  width: 100%;
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 20px;

  span {
    color: #798b83;
    width: 50%;
  }

  p {
    color: #21312a;
    font-size: 1rem;
  }
}

.eden-customer-service {
  margin-bottom: 40px;
}
.spaced {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.sub-head {
  border-bottom: 1px solid #f0f4f2;
  padding-bottom: 2px;
  font-size: 12px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 10px;
}
.head-title {
  border-bottom: 1px solid #f0f4f2;
  padding-bottom: 14px;
  font-size: 14px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-top: -1px;
}
.feedback {
  margin-bottom: 100px;
}
.top-space {
  margin-top: 30px;
}
.view-employees {
  color: #03a84e;
  cursor: pointer;
}
.faint-circle {
  border: 1px solid #f0f4f2;
  padding: 1px 2px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  margin-right: 4px;
}
</style>
